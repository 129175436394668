import { minWidthQueries } from '@operations/utils/useMediaQuery'
import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
  display: flex;
  ${({ wrap }) => wrap && `flex-wrap: ${wrap}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ padding }) => padding && { padding }};
  ${({ background }) => background && background};
  ${({ height }) => height && { height }};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`}};
  width: 100vw;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ overflow }) => overflow && { overflow }};
  position: relative;
  @media ${minWidthQueries.md} {
    ${({ marginTop, height }) => {
      if (marginTop === '0px') {
        return `height: ${height || '100vh'}`
      }
      return `height: calc(100vh - ${marginTop})`
    }}}
`

const FullScreenSection = ({
  children,
  background,
  height = 'auto',
  maxHeight,
  justify = 'center',
  align = 'center',
  marginTop = '0px',
  padding = '0',
  wrap,
  overflow = 'hidden',
  direction,
}) => (
  <StyledSection
    align={align}
    background={background}
    direction={direction}
    height={height}
    justify={justify}
    marginTop={marginTop}
    maxHeight={maxHeight}
    overflow={overflow}
    padding={padding}
    wrap={wrap}
  >
    {children}
  </StyledSection>
)

export default FullScreenSection
