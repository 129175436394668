import { maxWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const StyledVideo = styled.video`
  min-height: ${({ height }) => height || '100vh'};
  ${({ width }) => width && `width: ${width}`};
  min-width: 100%;
  object-fit: cover;
  overflow: hidden;
  cursor: ${({ theme }) => theme.cursor.default};
`

const FullScreenVideo = ({
  webm,
  mp4,
  style,
  height,
  width,
  muted = true,
  volume = 0.05,
  loop = true,
}) => {
  const videoRef = useRef(null)

  useEffect(() => {
    videoRef.current.volume = volume
  }, [volume])

  return (
    <StyledVideo
      ref={videoRef}
      autoPlay
      playsInline
      height={height}
      loop={loop}
      muted={muted}
      preload="auto"
      style={style}
      width={width}
    >
      {webm && <source src={webm} type="Video/MP4" />}
      {mp4 && <source src={mp4} type="Video/MP4" />}
    </StyledVideo>
  )
}

export default FullScreenVideo
